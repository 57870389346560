
.searchContainer {
  display: flex;
  gap: 1rem;
  margin: 2rem auto 0;
}
.searchInput {
  width: 100%;
  background-color: rgba(255,255,255,0.3);
  border: none;
  border-bottom: white 3px solid;
  border-radius: 0;
}